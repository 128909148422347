import * as propz from 'propz';
import { Participant } from 'models/event';
import { SchoolEvent } from 'models/event';
import { EVENT_RESULTS_MODE } from 'consts/event';
import { isClubEvent } from './event';
import {
   isMultipartyEvent,
   isInternalEvent,
   isHousesEvent,
   isInterSchoolsEvent
} from './event';
import {
   getTeamScoreByTeamId,
   getHouseScoreByHouseId,
   getSchoolScoreBySchoolId,
   convertPointsToStringWithoutDelimeter,
   getTeamExtraPointsByTeamId,
   getSchoolExtraPointsBySchoolId,
   getTeamAbbreviationResultByTeamId,
   getSchoolAbbreviationResultBySchoolId
} from './eventView';
import {
   isCricket,
   convertCricketPoints
} from './sport/cricket';
import logoImage from '../assets/logoImage.png';

export function getParticipantImg(participant: Participant): string {
   const houseImg = propz.get<string>(participant, ['house', 'pic']);
   const schoolImg = propz.get<string>(participant, ['school', 'pic']);

   const isHouseImgExist = typeof houseImg !== 'undefined';
   const isSchoolImgExist = typeof schoolImg !== 'undefined';

   switch (true) {
      case isHouseImgExist:
         return houseImg;
      case isSchoolImgExist:
         return schoolImg;
      default:
         return logoImage;
   }
}

export function getParticipantName(event: SchoolEvent, participant: Participant): string {
   const schoolName = getParticipantSchoolName(participant);
   const isSchoolNameExist = typeof schoolName !== 'undefined';

   const houseName = getHouseEventParticipantHouseName(participant);
   const isHouseNameExist = typeof houseName !== 'undefined';

   const teamName = getTeamEventParticipantTeamName(participant);
   const isTeamNameExist = typeof teamName !== 'undefined';

   switch (true) {
      case isClubEvent(event):
         return schoolName;
      case isTeamNameExist && isHouseNameExist:
         return `${teamName} / ${houseName}`;
      case isHouseNameExist:
         return houseName;
      case isTeamNameExist && isSchoolNameExist:
         return `${teamName} / ${schoolName}`;
      case isTeamNameExist:
         return teamName;
      //select team later
      case isSchoolNameExist:
         return schoolName;
      default:
         console.error('Can not find school name and team name');
         return '';
   }
}

export function getParticipantTeamResult(event: SchoolEvent, participant: Participant): string {
   const { team, school, house } = participant;

   let result;

   const isTeamExist = typeof team !== 'undefined';
   const participantTeamId = isTeamExist ? team.id : '';

   const isHouseExist = typeof house !== 'undefined';
   const participantHouseId = isHouseExist ? house.id : '';

   const isSchoolExist = typeof school !== 'undefined';
   const participantSchoolId = isSchoolExist ? school.id : '';

   switch (true) {
      case isCricket(event) && !isMultipartyEvent(event) && isInternalEvent(event): {
         const score = getTeamScoreByTeamId(event, participantTeamId);
         const { runs, wickets } = convertCricketPoints(score);

         result = `Runs: ${runs} / Wickets: ${wickets}`;

         break;
      }

      case isCricket(event) && !isMultipartyEvent(event) && isHousesEvent(event): {
         const score = typeof participant.team !== 'undefined'
            ? getTeamScoreByTeamId(event, participantTeamId)
            : getHouseScoreByHouseId(event, participantHouseId);

         const { runs, wickets } = convertCricketPoints(score);

         result = `Runs: ${runs} / Wickets: ${wickets}`;

         break;
      }

      case isCricket(event) && !isMultipartyEvent(event) && isInterSchoolsEvent(event): {
         const score = typeof participant.team !== 'undefined'
            ? getTeamScoreByTeamId(event, participantTeamId)
            : getSchoolScoreBySchoolId(event, participantSchoolId);

         const { runs, wickets } = convertCricketPoints(score);

         result = `Runs: ${runs} / Wickets: ${wickets}`;

         break;
      }

      case isInternalEvent(event):
         result = convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participantTeamId));

         break;

      case isHousesEvent(event):
         result = typeof participant.team !== 'undefined'
            ? convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participantTeamId))
            : convertPointsToStringWithoutDelimeter(event, getHouseScoreByHouseId(event, participantHouseId));

         break;

      case isInterSchoolsEvent(event):
         result = typeof participant.team !== 'undefined'
            ? convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participantTeamId))
            : convertPointsToStringWithoutDelimeter(event, getSchoolScoreBySchoolId(event, participantSchoolId));

         break;

      default:
         console.error('Can not find event type');
   }

   return String(result);
}

export function getParticipantExtraPointsResult(event: SchoolEvent, participant: Participant): string {
   const { team, school } = participant;
 
   if (team) {
     return getTeamExtraPointsByTeamId(event, team.id);
   } else if (school) {
     return getSchoolExtraPointsBySchoolId(event, school.id);
   }

   return ''; 
}

export function getParticipantAbbreviationResult(event: SchoolEvent, participant: Participant): string {
   const { team, school } = participant;

   let abbreviationResult = '';

   const participantTeamId = team ? team.id : '';
   const participantSchoolId = school ? school.id : '';

   switch (true) {
       case isInternalEvent(event):
           abbreviationResult = team 
             ? getTeamAbbreviationResultByTeamId(event, participantTeamId) || ''
             : '';
           break;
       case isHousesEvent(event):
           // add logic for houses here
           abbreviationResult = ''; // Placeholder for house logic
           break;
       case isInterSchoolsEvent(event):
            abbreviationResult = team
               ? getTeamAbbreviationResultByTeamId(event, participantTeamId) || ''
               : getSchoolAbbreviationResultBySchoolId(event, participantSchoolId) || '';
           break;
       default:
           console.error('Cannot find event type for abbreviation result');
           break;
   }

   return abbreviationResult;
}

export function getParticipantTeamResultNumber(event: SchoolEvent, participant: Participant): number {
   const { team, school, house } = participant;
   let result;

   const isTeamExist = typeof team !== 'undefined';
   const participantTeamId = isTeamExist ? team.id : '';

   const isHouseExist = typeof house !== 'undefined';
   const participantHouseId = isHouseExist ? house.id : '';

   const isSchoolExist = typeof school !== 'undefined';
   const participantSchoolId = isSchoolExist ? school.id : '';

   switch (true) {
      case isInternalEvent(event): {
         result = getTeamScoreByTeamId(event, participantTeamId);

         break;
      }

      case isHousesEvent(event): {
         result = typeof participant.team !== 'undefined'
            ? getTeamScoreByTeamId(event, participantTeamId)
            : getHouseScoreByHouseId(event, participantHouseId);

         break;
      }

      case isInterSchoolsEvent(event): {
         result = typeof participant.team !== 'undefined'
            ? getTeamScoreByTeamId(event, participantTeamId)
            : getSchoolScoreBySchoolId(event, participantSchoolId);

         break;
      }

      default:
         result = 0;
         console.error('Can not find event type');
   }

   return result;
}

export function getHouseEventParticipantHouseName(participant: Participant): string {
   return propz.get<string>(participant, ['house', 'name']);
}

export function getTeamEventParticipantTeamName(participant: Participant): string {
   return propz.get<string>(participant, ['team', 'name']);
}

export function getParticipantSchoolName(participant: Participant): string {
   return propz.get<string>(participant, ['school', 'name']);
}

export function getTextResult(event: SchoolEvent): string {
   let textResult;

   const { resultsMode } = event;

   switch (resultsMode) {
      case EVENT_RESULTS_MODE.PLACES:
         textResult = 'Place: ';

         break;

      case EVENT_RESULTS_MODE.POINTS:
         textResult = 'Points: ';

         break;

      default:
         textResult = '';

         break;
   }

   return textResult;
}
