import React from 'react';
import * as propz from 'propz';
import useMediaQuery from '@mui/material/useMediaQuery';
import Moment from 'moment';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament } from 'models/tournament';
import { PUBLIC_SITE_STATUS_OPTIONS } from 'consts/tournament';
import { SCREEN_SIZE } from 'consts/responsive';
import { getTournamentPublicSiteLink } from 'helpers/link';
import { getTournamentBookingLink } from 'helpers/tournament';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TournamentIcon from 'components/SvgIcons/TournamentIcon';

interface Props {
   tournament: Tournament;
   onTournamentInfoClick: (tournament: Tournament) => void;
   isShowSchool?: boolean;
};

interface WrapperProps {
   isTabletScreenSize: boolean;
};

interface LeftSideProps {
   isTabletScreenSize: boolean;
};

interface RightSideProps {
   isTabletScreenSize: boolean;
};

const Wrapper = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'isTabletScreenSize'
})<WrapperProps>(
   ({ isTabletScreenSize }) => sx({
      display: 'flex',
      flexDirection: isTabletScreenSize ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: isTabletScreenSize ? 'flex-start' : 'flex-end',
      gap: 2,
      border: '1px solid #ccc',
      borderRadius: '4px',
      p: {
         xs: 1,
         sm: 2,
         md: 3
      }
   })
);

const LeftSide = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'isTabletScreenSize'
})<LeftSideProps>(
   ({ isTabletScreenSize }) => sx({
      display: 'flex',
      flexDirection: isTabletScreenSize ? 'column' : 'row',
      width: isTabletScreenSize ? '100%' : 'auto',
      gap: 2
   })
);

const RightSide = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'isTabletScreenSize'
})<RightSideProps>(
   ({ isTabletScreenSize }) => sx({
      display: 'flex',
      flexDirection: isTabletScreenSize ? 'row' : 'column',
      alignItems: isTabletScreenSize ? 'center' : 'flex-end',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 1,
      width: isTabletScreenSize ? '100%' : 'auto',
      height: '100%'
   })
);

const TournamentImage = styled(Box)(
   sx({
      display: 'flex',
      minWidth: '150px',
      maxWidth: '150px',
      height: '100px',
      overflow: 'hidden',
      borderRadius: '4px',

      '& img': {
         width: '100%',
         objectFit: 'contain',
      }
   })
);

const TournamentIconWrapper = styled(Box)(
   sx({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '150px',
      height: '100px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundColor: '#7c7c7c26',
      borderRadius: '4px'
   })
);

const TournamentInfo = styled(Box)(
   sx({
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
   })
);

const TournamentTitle = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'left'
   })
);

const TournamentTimeAndLocation = styled(Box)(
   sx({
      display: 'flex',
      gap: '12px'
   })
);

const TournamentTime = styled(Typography)(
   sx({
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
   })
);

const TournamentLocation = styled(Typography)(
   sx({
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
   })
);

const BookingAndInfoButtonsWrapper = styled(Box)(
   sx({
      display: 'flex',
      gap: 2
   })
);

const InfoButton = styled(Button)(
   sx({
      '& .MuiButton-startIcon': {
         mr: 0,
         zIndex: -1
      }
   })
);

export default function TournamentCalendarItem(props: Props) {
   const { tournament, isShowSchool, onTournamentInfoClick } = props;
   const { photos, startTime, id } = tournament;

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const now = new Date();

   const formattedDate = Moment(startTime).format('DD-MM-YYYY HH:mm');
   const postcode = propz.get(tournament, ['venue', 'postcode'], '');
   const tournamentPhoto = photos[0];
   const tournamentPhotoUrl = propz.get(tournamentPhoto, ['picUrl'], '');
   const schoolName = propz.get(tournament, ['school', 'name'], '');
   const isTournamentPhotoUrlExist = tournamentPhotoUrl !== '';
   const tournamentWebsiteLink = getTournamentPublicSiteLink(tournament);
   const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
   const isPublicSiteAvailable = publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;

   const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
   const isAutoEnrollmentToWaitingList = propz.get(tournament, ['isAutoEnrollmentToWaitingList'], false);

   const isSpacesLeft = autoEnrollmentTeamsSettings.some((setting) => {
      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;

      return spacesLeft > 0;
   });

   const isSomeMaximumNumberTeamsEqualsZero = autoEnrollmentTeamsSettings.some((setting) => {
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      return maximumNumberTeams === 0;
   });

   const entriesOpen = propz.get(tournament, ['entriesOpen'], '');
   const deadlineForEntries = propz.get(tournament, ['deadlineForEntries'], '');

   const isEntriesOpenExist = entriesOpen !== '';
   const isDeadlineForEntriesExist = deadlineForEntries !== '';
   const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
   const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;

   const isBookOnlineButtonShow = isEntriesOpenExist &&
      isEntriesOpenDatePassed &&
      isDeadlineForEntriesExist &&
      !isDeadlineForEntriesPassed;

   const bookOnlineButtonText = (!isSpacesLeft || isAutoEnrollmentToWaitingList) && !isSomeMaximumNumberTeamsEqualsZero
      ? 'Book onto WL'
      : 'Book online';

   const onBookOnlineClick = (tournamentId: string) => {
      const url = getTournamentBookingLink(tournamentId);

      window.open(url, '_blank');
   };

   return (
      <Wrapper isTabletScreenSize={isTabletScreenSize} data-starttime={tournament.startTime}>
         <LeftSide isTabletScreenSize={isTabletScreenSize}>
            {isTournamentPhotoUrlExist
               ? <TournamentImage>
                     <img src={tournamentPhotoUrl} alt="tournament_picture" />
                 </TournamentImage>
               : <TournamentIconWrapper>
                  <TournamentIcon width={35} height={35} />
               </TournamentIconWrapper>
            }

            <TournamentInfo>
               <TournamentTitle>{ tournament.name }</TournamentTitle>

               <TournamentTimeAndLocation>
                  <TournamentTime>
                     <AccessTimeIcon color="info" />
                     { formattedDate }
                  </TournamentTime>

                  <TournamentLocation>
                     <LocationOnIcon color="info" />
                     { postcode }
                  </TournamentLocation>
               </TournamentTimeAndLocation>
            </TournamentInfo>
         </LeftSide>

         <RightSide isTabletScreenSize={isTabletScreenSize}>
            {isShowSchool &&
               <Chip label={schoolName} variant="outlined" color="success" size="small" />
            }

            <BookingAndInfoButtonsWrapper>
               {isBookOnlineButtonShow &&
                  <Button
                     onClick={() => onBookOnlineClick(id)}
                     color='error'
                     size="small"
                     variant='outlined'
                     sx={{ whiteSpace: 'nowrap' }}
                  >
                     {bookOnlineButtonText}
                  </Button>
               }

               <InfoButton
                  size="small"
                  variant="outlined"
                  startIcon={<InfoOutlinedIcon />}
                  onClick={() => onTournamentInfoClick(tournament)}
               />
            </BookingAndInfoButtonsWrapper>

            {isPublicSiteAvailable &&
               <Button
                  variant="outlined"
                  size="small"
                  href={tournamentWebsiteLink}
                  target='_blank'
                  sx={{ whiteSpace: 'nowrap' }}
               >
                  Tournament website
               </Button>
            }
         </RightSide>
      </Wrapper>
   );
}
