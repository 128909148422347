import React, { useState } from 'react';
import * as propz from 'propz';
import Moment from 'moment';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament, TournamentAutoEnrollmentTeamsSetting, TournamentTag } from 'models/tournament';
import { School } from 'models/school';
import { GENDER_TYPE } from 'types/common';
import { SPORT_GENDER_TYPE } from 'types/sport';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/sport';
import { AGE_GROUPS, AGE_GROUPS_SORTED, KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { CLUB_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/club';
import { PUBLIC_SITE_STATUS_OPTIONS, TOURNAMENT_TYPE } from 'consts/tournament';
import {
   getTournamentPublicSiteLink,
   getTournamentTVPublicSiteLink
} from 'helpers/link';
import { getAgesGroupFormatted } from 'helpers/accessors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import Map from 'components/Map';

interface Props {
   tournament: Tournament;
   school: School;
}

type TableRowType = {
   title: string;
   value: string | JSX.Element | JSX.Element[];
   text?: string; 
 };

const AdditionalText = styled(Typography)(
   sx({
      mt: 1,
      opacity: '0.7',
      fontSize: '12px',
      fontStyle: 'italic'
   })
);

const SpaicesRemainingErrorWrapper = styled(Box)(
   sx({
      p: 2
   })
);

const SpaicesRemainingErrorText = styled(Typography)(
   sx({
      fontStyle: 'italic', fontWeight: 'bold'
   })
);

interface TournamentTagsDisplayProps {
   tags: TournamentTag[];
 }
 
 const TournamentTagsDisplay: React.FC<TournamentTagsDisplayProps> = ({ tags }) => {
   return (
     <Box sx={{ 
       display: 'flex', 
       flexWrap: 'wrap', 
       justifyContent: 'flex-end', 
       mt: 0, 
       mb: 1, 
     }}>
       {tags.filter(tag => tag.isPublic).map(tag => (
         <Box
           key={tag.id}
           sx={{
             background: `${tag.tagColor}`,
             border: `1px solid ${tag.tagColor}`,
             color: 'white',
             borderRadius: '4px',
             p: '6px 10px', 
             m: 0.5,
             textAlign: 'center',
             fontSize: '0.65rem', 
             fontWeight: 'bold',
             textTransform: 'uppercase',
             cursor: 'pointer',
             boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
             maxWidth: '100%',
             overflow: 'hidden',
             textOverflow: 'ellipsis',
             fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
           }}
         >
           {tag.tagName}
         </Box>
       ))}
     </Box>
   );
 };

export default function Details(props: Props) {
   const { tournament, school } = props;

   const [isMapOpen, setIsMapOpen] = useState(false);

   const isTournamentExist = typeof tournament !== 'undefined'

   const startDate = propz.get(tournament, ['startTime'], '');
   const endDate = propz.get(tournament, ['endTime'], '');
   const areStartAndEndDatesTheSameDay = new Date(startDate).setHours(0,0,0) === new Date(endDate).setHours(0,0,0);

   const deadlineForEntries = propz.get(tournament, ['deadlineForEntries']);
   const isDeadlineForEntriesExist = typeof deadlineForEntries !== 'undefined' && deadlineForEntries !== null;

   const deadlineFormatted = isDeadlineForEntriesExist
      ? Moment(deadlineForEntries).format('dddd Do MMMM YYYY HH:mm')
      : 'When closed by the organisers';

   const now = new Date();

   const isSchoolUnion = school.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION;

   const tournamentType = propz.get(tournament, ['type'], '');
   const isIndividualTournament = tournamentType === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT;
   const activeSchoolName = propz.get(school, ['name'], '');
   const activeSchoolEmail = propz.get(school, ['email'], '');
   const startDateFormatted = Moment(startDate).format('dddd Do MMMM YYYY, h:mm a');
   const endDateFormatted = Moment(endDate).format('dddd Do MMMM YYYY, h:mm a');
   const endTimeFormatted = Moment(endDate).format('h:mm a');
   const placeDataName = propz.get(tournament, ['venue', 'placeData', 'name'], '');
   const address = propz.get(tournament, ['school', 'address'], '');
   const postcode = propz.get(tournament, ['venue', 'postcodeData', 'postcode'], '');
   const isPlaceDataExist = placeDataName !== '';
   const isPostcodeExist = postcode !== '';
   const point = propz.get(tournament, ['venue', 'point']);
   const sportName = propz.get(tournament, ['sport', 'name'], '');
   const agesFormatted = getAgesGroupFormatted(tournament, school);

   const gender: GENDER_TYPE = propz.get(tournament, ['gender']);
   const tournamentGender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[gender] ||
      SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender as SPORT_GENDER_TYPE];

   const organisers = propz.get(tournament, ['organisers'], '');
   const organisersEmail = propz.get(tournament, ['organisersEmail'], '');
   const isOrganisersExist = organisers !== '';
   const isOrganisersEmailExist = organisersEmail !== '';
   const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
   const isAutoEnrollmentTeamsSettingsExist = autoEnrollmentTeamsSettings.length > 0;
   const isAutoEnrollmentTeams = propz.get(tournament, ['isAutoEnrollmentTeams'], false);
   const tournamentPublicSiteLink = isTournamentExist ? getTournamentPublicSiteLink(tournament) : '';
   const tournamentTVPublicSiteLink = isTournamentExist ? getTournamentTVPublicSiteLink(tournament) : '';
   const domain = propz.get(tournament, ['domain'], '');
   const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
   const publicBigscreenSiteStatus = propz.get(tournament, ['publicBigscreenSite', 'status'], '');
   const isAgeGroupsOnWebSiteVisible = propz.get(tournament, ['isAgeGroupsOnWebSiteVisible'], false);
   const isAutoEnrollmentToWaitingList = propz.get(tournament, ['isAutoEnrollmentToWaitingList'], false);
   const isPublicSiteLinkAvailable = domain !== '' && publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;
   const isPublicBigscreenSiteLinkAvailable = publicBigscreenSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;
   const isShowMap = propz.get(tournament, ['venue', 'venueType']) !== 'TBD';
   const entriesOpen = propz.get(tournament, ['entriesOpen'], '');
   const entriesOpenFormatted = Moment(entriesOpen).format('dddd Do MMMM YYYY HH:mm');
   const isEntriesOpenExist = entriesOpen !== '';
   const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
   const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;

   const isSpacesLeft = autoEnrollmentTeamsSettings.some((setting) => {
      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;

      return spacesLeft > 0;
   });

   const isSpacesUnlimited = autoEnrollmentTeamsSettings.some((setting) => {
    const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

    return maximumNumberTeams === 0;
 });

   const isBookOntoWaitingList = (!isSpacesLeft || isAutoEnrollmentToWaitingList) &&
      isEntriesOpenExist &&
      isEntriesOpenDatePassed &&
      isDeadlineForEntriesExist &&
      !isDeadlineForEntriesPassed;

   const isSpaicesRemainingErrorShow = isSchoolUnion &&
      isAutoEnrollmentTeams &&
      isBookOntoWaitingList &&
      !isSpacesUnlimited;

   const resultsWebsiteLink = isPublicSiteLinkAvailable ? (
      <Link href={tournamentPublicSiteLink} target="_blank">
         {tournamentPublicSiteLink}
      </Link>
   ) : (
      'The link is not available yet.'
   );

   const tournamentTVLink = isPublicBigscreenSiteLinkAvailable ? (
      <Link href={tournamentTVPublicSiteLink} target="_blank">
         {tournamentTVPublicSiteLink}
      </Link>
   ) : (
      'The link is not available yet.'
   );

   const getAge = (age: number) => {
      const { ageGroupsNaming } = school;
      const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);

      return ageGroups[age];
   };

   const renderRemainingSpacesRow = (setting: TournamentAutoEnrollmentTeamsSetting) => {
      const { age, gender } = setting;

      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;
      const teamsAge = isIndividualTournament ? agesFormatted : getAge(age);
      const teamsGender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[gender] ||
         SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender as SPORT_GENDER_TYPE];

      const remainingSpaceRow =
         maximumNumberTeams === 0
            ? `${teamsAge} ${teamsGender} - Unlimited`
            : `${teamsAge} ${teamsGender} - ${spacesLeft} space(s) left`;

      return remainingSpaceRow;
   };

   const renderRemainingSpacesSection = () => {
      return isIndividualTournament && isAutoEnrollmentTeamsSettingsExist
         ? renderRemainingSpacesRow(autoEnrollmentTeamsSettings[0])
         : autoEnrollmentTeamsSettings.map((item, index) => {
            return (
               <Box key={`auto-enrollment-setting-${index}`}>
                  {renderRemainingSpacesRow(item)}
               </Box>
            );
      })
   };

   const getVenue = () => {
      const venue = isPlaceDataExist ? placeDataName : address;

      const { coordinates } = point;
      const [ latitude, longitude ] = coordinates;

      const googleMapsLink = `http://maps.google.com/maps?q=${longitude},${latitude}+(My+Point)&z=14&ll=${longitude},${latitude}`;

      return (
         <>
            <Box>
               <Link href={googleMapsLink} target="_blank">
                  {isPostcodeExist
                     ? `${venue}, ${postcode}`
                     : `${venue}`
                  }
               </Link>

               {isShowMap &&
                  <IconButton
                     onClick={() => setIsMapOpen(!isMapOpen)}
                     sx={{ml: 1, p: 0}}
                     color="primary"
                  >
                     <LocationOnIcon />
                  </IconButton>
               }
            </Box>
            
            {isMapOpen && <Map point={point} />}
         </>
      );
   };

   const getEmail = () => {
      const email = isOrganisersEmailExist
         ? organisersEmail
         : activeSchoolEmail;

      return <Link href={`mailto:${email}`}>{email}</Link>
   };

   function isEffectivelyEmpty(htmlString: string): boolean {
      const tempDivElement = document.createElement('div');
      tempDivElement.innerHTML = htmlString;
      return !tempDivElement.textContent?.trim();
  }

   const rows = [];

   if (tournament.description && !isEffectivelyEmpty(tournament.description)) {
      rows.push({
         title: '',
         value: <div dangerouslySetInnerHTML={{ __html: tournament.description }} />,
      });
   }

   rows.push(
      {
         title: 'Start time',
         value: areStartAndEndDatesTheSameDay ? `${startDateFormatted} - ${endTimeFormatted}` : startDateFormatted
      },
      {
         title: 'Finish time',
         value: endDateFormatted
      },
      {
         title: 'Venue',
         value: getVenue()
      },
      {
         title: isAgeGroupsOnWebSiteVisible ? 'Gender / Age' : 'Gender',
         value: isAgeGroupsOnWebSiteVisible ? `${tournamentGender} / ${agesFormatted}`: tournamentGender
      },
      {
         title: 'Sport',
         value: sportName
      },
      {
         title: 'Organisers',
         value: isOrganisersExist ? organisers : activeSchoolName
      },
      {
         title: 'E-mail',
         value: getEmail()
      },
      {
         title: 'Entries open',
         value: entriesOpenFormatted
      },
      {
         title: 'Entries close',
         value: deadlineFormatted
      },
      {
         title: 'Results website',
         value: resultsWebsiteLink
      },
      {
         title: 'Tournament TV',
         value: tournamentTVLink
      },
      {
         title: 'Spaces remaining',
         value: renderRemainingSpacesSection()
      }
   );

   const rowsFiltered = rows.filter(row => {
      const { title } = row;

      switch (title) {
         case 'Finish time':
            return !areStartAndEndDatesTheSameDay;

         case 'Entries open':
            return isEntriesOpenExist && !isEntriesOpenDatePassed

         case 'Spaces remaining':
            return isSchoolUnion && isAutoEnrollmentTeams && !isAutoEnrollmentToWaitingList
      
         default:
            return true;
      }
   })

   const tournamentTags = propz.get(tournament, ['tournamentTags'], []);

   return (
      <Box>
        {tournamentTags.length > 0 && <TournamentTagsDisplay tags={tournamentTags} />}
        <TableContainer>
          <Table>
            <TableBody>
            {rowsFiltered.map((row: TableRowType, index) => {
               const isDescriptionRow = row.title === '';
               return (
                  <TableRow key={`tournament-details-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                     {!isDescriptionRow ? (
                     <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', maxWidth: '130px' }}>
                        <Box>{row.title}:</Box>
                        {row.text && <AdditionalText>{row.text}</AdditionalText>}
                     </TableCell>
                     ) : null}
                     <TableCell align="left" sx={{ fontStyle: 'italic' }} colSpan={isDescriptionRow ? 2 : 1}>
                     {row.value}
                     </TableCell>
                  </TableRow>
               );
               })}
            </TableBody>
          </Table>
        </TableContainer>
    
        {isSpaicesRemainingErrorShow &&
          <SpaicesRemainingErrorWrapper>
            <SpaicesRemainingErrorText color='error'>
              Once spaces are full, you will be placed onto a waiting list and will be contacted when places become available.
            </SpaicesRemainingErrorText>
          </SpaicesRemainingErrorWrapper>
        }
      </Box>
    );
    
}