import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament } from 'models/tournament';
import { School } from 'models/school';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentMUI from '@mui/material/DialogContent';
import DialogTitleMUI from '@mui/material/DialogTitle';
import TournamentDetails from 'views/School/Tournaments/TournamentDetails';

interface Props {
   tournament: Tournament;
   school: School;
   onBookOnlineClick: (tournamentId: string) => void;
   onClose: () => void;
};

const DialogContent = styled(DialogContentMUI)(
   sx({
      p: 0,
      borderTop: 0
   })
);

const DialogTitle = styled(DialogTitleMUI)(
   sx({
      background: '#2b8ded'
   })
);

export default function TournamentInfo(props: Props) {
   const { tournament, school, onBookOnlineClick, onClose } = props;
   const { id } = tournament;

   const now = new Date();

   const tournamentName = propz.get(tournament, ['name'], '');
   const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
   const entriesOpen = propz.get(tournament, ['entriesOpen'], '');
   const deadlineForEntries = propz.get(tournament, ['deadlineForEntries'], '');
   const isEntriesOpenExist = entriesOpen !== '';
   const isDeadlineForEntriesExist = deadlineForEntries !== '';
   const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
   const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;
   const isAutoEnrollmentToWaitingList = propz.get(tournament, ['isAutoEnrollmentToWaitingList'], false);

   const isSpacesLeft = autoEnrollmentTeamsSettings.some((setting) => {
      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;

      return spacesLeft > 0;
   });

   const isSomeMaximumNumberTeamsEqualsZero = autoEnrollmentTeamsSettings.some((setting) => {
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      return maximumNumberTeams === 0;
   });

   const isBookOnlineButtonShow = isEntriesOpenExist &&
      isEntriesOpenDatePassed &&
      isDeadlineForEntriesExist &&
      !isDeadlineForEntriesPassed;

   const bookOnlineButtonText = ((!isSpacesLeft || isAutoEnrollmentToWaitingList) && !isSomeMaximumNumberTeamsEqualsZero)
      ? 'Book onto WL'
      : 'Book online';

   return (
      <>
         <DialogTitle color={'white'}>
            {tournamentName}
         </DialogTitle>

         <DialogContent dividers={true}>
            <TournamentDetails tournament={tournament} school={school} />
         </DialogContent>

         <DialogActions sx={{justifyContent: isBookOnlineButtonShow ? 'space-between' : 'flex-end'}}>
            {isBookOnlineButtonShow &&
               <Button
                  onClick={() => onBookOnlineClick(id)}
                  color='error'
                  variant='outlined'
               >
                  {bookOnlineButtonText}
               </Button>
            }

            <Button onClick={onClose}>Close</Button>
         </DialogActions>
      </>
   );
}
