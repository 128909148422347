import React from 'react';
import moment from 'moment';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolEventTeamData, SchoolEventSchool, SchoolEvent } from 'models/event';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import {
   isInternalEvent,
   isHousesEvent,
   isIndividualSportEvent,
   isTeamSportEvent,
   isMultipartyEvent,
   isOpponentHasOneTeam,
   isSchoolHasOneTeam,
   isEventStatusCanceled,
   isTournamentEvent
} from 'helpers/event';
import { isIndividualTournament } from 'helpers/tournament';
import { getEventResultForCalendar } from 'helpers/calendar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import squadInTouchLogo from 'assets/logoImage.png';

interface Props {
   event: SchoolEvent;
   schoolId: string;
   type?: string;
   onEventClick: (event: SchoolEvent) => void;
};

const CardWrapper = styled(Box)(
   sx({
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      p: 2,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden',
      borderRadius: '4px',
      background: '#fff',
      flex: {
         xs: '0 1 auto',
         md: '0 1 300px'
      },
      cursor: 'pointer',
      transition: '0.2s ease-in-out',

      '&:hover': {
         borderColor: '#1976d2'
      }
   })
);

const OpponentsWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '120px'
   })
);

const EventTimeWrapper = styled(Box)(
   sx({
      mt: 2
   })
);

const SportType = styled(Typography)(
   sx({
      height: '50px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#1976D2',
      mb: 1,
      flex: '0 1 auto'
   })
);

const Opponents = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-around',
      gap: '10px'
   })
);

const SchoolLogoWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
   })
);

const SchoolLogo = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80px',
      height: '80px',
      textAlign: 'center',
      mb: 2,

      '& img': {
         objectFit: 'contain'
      }
   })
);

const SchoolName = styled(Typography)(
   sx({
      color: '#ccc',
      fontSize: '12px',
      textAlign: 'center',
      lineHeight: '15px'
   })
);

const EventName = styled(Typography)(
   sx({
      height: '30px',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '15px',
      mb: 2
   })
);

const EventResult = styled(Typography)(
   sx({
      height: '15px',
      flex: 1,
      my: 2,
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#1976D2'
   })
);

const EventTime = styled(Typography)(
   sx({
      textAlign: 'center',
      fontSize: '14px',
      color: '#919191'
   })
);

const CanceledOverlay = styled(Typography)(
   sx({
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      color: 'red',
      fontSize: '150%',
      background: 'rgba(0,0,0,0.4)',

      '& span': {
         background: 'rgba(255,255,255,0.6)',
         p: 1,
         borderRadius: 2
      }
   })
);

export default function EventCard(props: Props) {
   const { event, schoolId, type, onEventClick } = props;

   const isTournamentEventCondition = isTournamentEvent(event);
   const tournament = propz.get(event, ['tournament']);
   const isIndividualTournamentEventCondition = isTournamentEventCondition && isIndividualTournament(tournament);

   const teamsData = propz.get(event, ['teamsData'], []);
   const housesData = propz.get(event, ['housesData']);
   const sportName = propz.get(event, ['sport', 'name'], '');
   const eventName = propz.get(event, ['generatedNames', schoolId], '');

   const isTypeResults = type === ACTIVITIES_BLOCK_TYPE.RESULTS;

   const isHousesDataExist = typeof housesData !== 'undefined' && housesData.length > 0;
   const isOverTwoHouses = isHousesDataExist && housesData.length > 2;
   const isTwoHouses = isHousesDataExist && housesData.length === 2;

   const invitedSchools = propz.get(event, ['invitedSchools'], []);
   const firstInvitedSchool = invitedSchools[0];
   const invitedSchoolPic = propz.get(firstInvitedSchool, ['pic'], '');

   const invitedSchoolsIds = invitedSchools.map((school: SchoolEventSchool) => school.id);
   const isCurrentSchoolInvited = invitedSchoolsIds.includes(schoolId);
   const inviterSchoolLogo = propz.get(event, ['inviterSchool', 'pic'], '');
   const inviterSchoolId = propz.get(event, ['inviterSchoolId'], '');
   const inviterSchoolName = propz.get(event, ['inviterSchool', 'name'], '');

   const inviterSchoolTeamData = teamsData.find((team: SchoolEventTeamData) => team.schoolId === inviterSchoolId);
   const inviterSchoolTeamDataName = propz.get(inviterSchoolTeamData, ['name'], '');
   const inviterSchoolTeamName = isSchoolHasOneTeam(event) ? inviterSchoolTeamDataName : inviterSchoolName;

   const isInvitedSchoolLogoExist = invitedSchoolPic !== '';
   const invitedSchoolsCount = invitedSchools.length;
   const isInvitedSchoolsOverOne = invitedSchoolsCount > 1;
   const invitedSchoolLogo = (invitedSchoolsCount > 1 || !isInvitedSchoolLogoExist) ? squadInTouchLogo : invitedSchoolPic;
   let invitedSchoolName: string = '';

   switch (true) {
      case isInvitedSchoolsOverOne:
         invitedSchoolName = 'Various opponents';
         break;

      case isOpponentHasOneTeam(event):
         const invitedSchoolTeamData = teamsData.find((team: any) => {
            const invitedSchoolId = propz.get(firstInvitedSchool, ['id'], '');

            return team.schoolId === invitedSchoolId;
         });

         invitedSchoolName = propz.get(invitedSchoolTeamData, ['name'], '');
         break;

      default:
         invitedSchoolName = propz.get(firstInvitedSchool, ['name'], '');
         break;
   }

   const firstHouseName = isHousesDataExist ? housesData[0].name : '';
   const secondHouseName = isHousesDataExist ? housesData[1].name : '';

   const startTime = propz.get(event, ['startTime'], '');
   const formattedDate = moment(startTime).format('D MMMM YYYY');
   const formattedTime = moment(startTime).format('h:mm a');

   const renderSchoolLogos = (data: any) => {
      const {
         leftSideLogo,
         leftSideName,
         rightSideLogo,
         rightSideName
      } = data;

      return (
         <>
            <SchoolLogoWrapper>
               <SchoolLogo>
                  <img src={leftSideLogo} alt={leftSideName} loading="lazy" width="100%" />
               </SchoolLogo>
               <SchoolName>{leftSideName}</SchoolName>
            </SchoolLogoWrapper>

            <SchoolLogoWrapper>
               <SchoolLogo>
                  <img src={rightSideLogo} alt={rightSideName} loading="lazy" width="100%" />
               </SchoolLogo>
               <SchoolName>{rightSideName}</SchoolName>
            </SchoolLogoWrapper>
         </>
      );
   };

   const renderOpponents = () => {
      switch (true) {
         case isIndividualTournamentEventCondition:
            return (
               <SchoolLogoWrapper>
                  <SchoolLogo>
                     <img
                        src={inviterSchoolLogo}
                        srcSet={inviterSchoolLogo}
                        alt={inviterSchoolName}
                        loading="lazy"
                        width="100%"
                     />
                  </SchoolLogo>
                  <SchoolName>{inviterSchoolName}</SchoolName>
               </SchoolLogoWrapper>
            );

         case isHousesEvent(event) && isTeamSportEvent(event) && isMultipartyEvent(event):
         case isHousesEvent(event) && isIndividualSportEvent(event) && isOverTwoHouses:
         case isInternalEvent(event) && isTeamSportEvent(event) && isMultipartyEvent(event):
         case isInternalEvent(event) && isIndividualSportEvent(event):
            return (
               <SchoolLogoWrapper>
                  <SchoolLogo>
                     <img
                        src={inviterSchoolLogo}
                        srcSet={inviterSchoolLogo}
                        alt={inviterSchoolName}
                        loading="lazy"
                        width="100%"
                     />
                  </SchoolLogo>
               </SchoolLogoWrapper>
            );

         case isHousesEvent(event) && isIndividualSportEvent(event) && isTwoHouses:
         case isHousesEvent(event) && isTeamSportEvent(event) && !isMultipartyEvent(event):
            return renderSchoolLogos({
               leftSideLogo: inviterSchoolLogo,
               leftSideName: firstHouseName,
               rightSideLogo: invitedSchoolLogo,
               rightSideName: secondHouseName
            });

         default:
            const opponentsData = isCurrentSchoolInvited
               ? {
                    leftSideLogo: invitedSchoolLogo,
                    leftSideName: invitedSchoolName,
                    rightSideLogo: inviterSchoolLogo,
                    rightSideName: inviterSchoolTeamName
                 }
               : {
                    leftSideLogo: inviterSchoolLogo,
                    leftSideName: inviterSchoolTeamName,
                    rightSideLogo: invitedSchoolLogo,
                    rightSideName: invitedSchoolName
                 };

            return renderSchoolLogos(opponentsData);
      }
   };

   const onCardClick = () => {
      const isOnEventClickExist = typeof onEventClick !== 'undefined';
      isOnEventClickExist && onEventClick(event);
   };

   return (
      <CardWrapper onClick={onCardClick}>
         {isEventStatusCanceled(event) && (
            <CanceledOverlay>
               <Box component="span">CANCELLED</Box>
            </CanceledOverlay>
         )}
         <SportType>{sportName}</SportType>

         <OpponentsWrapper>
            <Opponents>{renderOpponents()}</Opponents>
         </OpponentsWrapper>

         {isTypeResults && <EventResult>{getEventResultForCalendar(event, schoolId)}</EventResult>}

         <EventTimeWrapper sx={{ mt: 2 }}>
            <EventName>{eventName}</EventName>
            <EventTime>{formattedDate}</EventTime>
            <EventTime>{formattedTime}</EventTime>
         </EventTimeWrapper>
      </CardWrapper>
   );
}
