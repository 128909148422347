import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Promise } from 'bluebird';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { Photo } from 'models/photo';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { HOME_PAGE_LIMIT, DEFAULT_SKIP } from 'consts/table';
import {
   getSchoolUnionPromoteTournaments,
   getSchoolUnionUpcomingTournaments,
   getSchoolUnionPastTournaments
} from 'services/schoolUnion/tournaments';
import { getAllSchoolsIncludedInUnion } from 'services/schoolUnion/schools';
import { getSchoolPhotos } from 'services/school';
import { getTournamentBookingLink } from 'helpers/tournament';
import { sortByNameAsc } from 'helpers/sorting';
import Box from '@mui/material/Box';
import DialogMUI from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ActivitiesBlock from 'components/ActivitiesBlock';
import TournamentSlider from './TournamentSlider';
import SchoolSlider from './SchoolSlider';
import TournamentCalendar from './TournamentCalendar';
import HomeSlider from 'components/HomeSlider';
import PageWrapper from 'components/PageWrapper';
import TournamentInfo from 'components/TournamentInfo';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';
import Loader from 'components/loader';

interface Props {
   school: School;
};

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

const Dialog = styled(DialogMUI)(
   sx({
      '& .MuiDialog-container': {
         alignItems: 'initial'
      }
   })
);

export default function Home(props: Props) {
   const { school } = props;
   const { id: activeSchoolId, defaultAlbumId } = school;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isTournamentModalOpen, setIsTournamentModalOpen] = useState<boolean>(false);
   const [upcomingTournaments, setUpcomingTournaments] = useState<Tournament[]>([]);
   const [pastTournaments, setPastTournaments] = useState<Tournament[]>([]);
   const [promoteTournaments, setPromoteTournaments] = useState<Tournament[]>([]);
   const [schoolPhotos, setSchoolPhotos] = useState<Photo[]>([]);
   const [schoolsIncludedInUnion, setSchoolsIncludedInUnion] = useState<School[]>([]);
   const [schoolsIncludedInUnionFiltered, setSchoolsIncludedInUnionFiltered] = useState<School[]>([]);
   const [selectedItem, setSelectedItem] = useState<Tournament>();

   const isSchoolPhotosExist = schoolPhotos.length > 0;

   const integrations = propz.get(school, ['integrations'], []);
   const schoolTwitterProfileName = integrations[0];
   const isTwitterProfileExist = typeof schoolTwitterProfileName !== 'undefined' && schoolTwitterProfileName !== '';

   const subUnions = schoolsIncludedInUnion.filter(item => item.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION);

   const isSelectedItemExist = typeof selectedItem !== 'undefined';

   const isPromoteTournamentsExist = promoteTournaments.length > 0;

   const schoolPhotosUrls: string[] = isSchoolPhotosExist
      ? schoolPhotos.map((item: Photo) => item.picUrl)
      : [defaultLeaguePhoto];

   useEffect(() => {
      setIsLoading(true);

      const filter = {
        limit: HOME_PAGE_LIMIT,
        skip: DEFAULT_SKIP
     }

      const promises = [
         getSchoolUnionPromoteTournaments(activeSchoolId),
         getSchoolUnionUpcomingTournaments(activeSchoolId, filter),
         getSchoolUnionPastTournaments(activeSchoolId, filter),
         getAllSchoolsIncludedInUnion(activeSchoolId),
         getSchoolPhotos(school, defaultAlbumId)
      ];

      Promise.all(promises).then(([
         promoteTournaments,
         upcomingTournaments,
         pastTournaments,
         schools,
         photos
      ]) => {
         const schoolsSorted = schools.sort(sortByNameAsc);

         setPromoteTournaments(promoteTournaments);
         setUpcomingTournaments(upcomingTournaments);
         setPastTournaments(pastTournaments);
         setSchoolsIncludedInUnion(schoolsSorted);
         setSchoolPhotos(photos);
         setSchoolsIncludedInUnionFiltered(schoolsSorted);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onBookOnlineClick = (tournamentId: string) => {
      const url = getTournamentBookingLink(tournamentId);

      window.open(url, '_blank');
   };

   const renderTournamentInfoModal = () => {
      return (
         <Dialog
            open={isTournamentModalOpen}
            onClose={closeModal}
            scroll='paper'
            fullWidth={true}
            maxWidth='sm'
         >
            {isSelectedItemExist &&
               <TournamentInfo
                  tournament={selectedItem}
                  school={school}
                  onClose={closeModal}
                  onBookOnlineClick={onBookOnlineClick}
               />
            }
         </Dialog>
      );
   };

   const onTournamentInfoClick = (tournament: Tournament) => {
      setSelectedItem(tournament)
      setIsTournamentModalOpen(true);
   };

   const closeModal = () => {
      setIsTournamentModalOpen(false);
   };

   const onSearchSchool = (value: string) => {
      const schoolsIncludedInUnionFiltered = schoolsIncludedInUnion.filter(item => {
         const name = propz.get(item, ['name'], '');
         const nameFormatted = name.toLowerCase();
         const valueFormatted = value.toLowerCase();

         return nameFormatted.includes(valueFormatted);
      });

      setSchoolsIncludedInUnionFiltered(schoolsIncludedInUnionFiltered);
   };

   if (isLoading) {
      return <Loader />
   }

   return (
      <>
         {renderTournamentInfoModal()}

         {!isPromoteTournamentsExist &&
            <SliderWrapper>
               <HomeSlider imageUrls={schoolPhotosUrls} />
            </SliderWrapper>
         }

         <PageWrapper>
            {isPromoteTournamentsExist &&
               <TournamentSlider
                  tournaments={promoteTournaments}
                  onTournamentInfoClick={onTournamentInfoClick}
                  onBookOnlineClick={onBookOnlineClick}
               />
            }

            <Divider sx={{ my: 4 }} />

            <Grid container spacing={4}>
               <Grid item lg={12}>
                  <ActivitiesBlock
                     title='Upcoming events'
                     type={ACTIVITIES_BLOCK_TYPE.TOURNAMENTS}
                     items={upcomingTournaments}
                     showMoreLink='/upcomingTournaments'
                     school={school}
                     onItemClick={(tournament) => onTournamentInfoClick(tournament as Tournament)}
                  />

                  <ActivitiesBlock
                     title='Recent results'
                     type={ACTIVITIES_BLOCK_TYPE.TOURNAMENTS}
                     items={pastTournaments}
                     showMoreLink='/tournamentResults'
                     school={school}
                     onItemClick={(tournament) => onTournamentInfoClick(tournament as Tournament)}
                  />
               </Grid>
            </Grid>

            <TournamentCalendar school={school} subUnions={subUnions} onTournamentInfoClick={onTournamentInfoClick} />

            <SchoolSlider schools={schoolsIncludedInUnionFiltered} onSearch={onSearchSchool} />
         </PageWrapper>
      </>
   );
};
