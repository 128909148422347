import axios from 'axios';
import * as propz from 'propz';
import { SORT, DEFAULT_SKIP, LIMIT_ALL, DEFAULT_LIMIT } from 'consts/table';

export function getAllSchoolUnionTournaments(activeSchoolIds: string | string[]) {
   const schoolIds = Array.isArray(activeSchoolIds) ? activeSchoolIds : [activeSchoolIds];

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               schoolId: {
                  $in: schoolIds
               }
            },
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
            order: `startTime ${SORT.ASC}`
         }
      }
   };

   return axios.get(`${window.apiBase}/public/tournaments`, config)
      .then(response => {
         return response.data;
      });
};

export function getSchoolUnionPromoteTournaments(activeSchoolId: string) {
  const config = {
     params: {
        filter: {
           where: {
              isPublishOnTheWebsite: true,
              isPromoteTournament: true,
              schoolId: {
                 $in: [activeSchoolId]
              }
           },
           skip: DEFAULT_SKIP,
           limit: LIMIT_ALL,
           order: `startTime ${SORT.ASC}`
        }
     }
  };

  return axios.get(`${window.apiBase}/public/tournaments`, config)
     .then(response => {
        return response.data;
     });
};

export function getAllSchoolUnionTournamentsCount(activeSchoolId: string) {
   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               schoolId: {
                  $in: [activeSchoolId]
               }
            }
         }
      }
   };

   return axios.get(`${window.apiBase}/public/tournaments/count`, config)
      .then(response => {
         return response.data;
      });
};

export function getAllSchoolUnionUpcomingTournaments(activeSchoolId: string) {
   const now = new Date();

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $gte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               }
            },
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
            order: `startTime ${SORT.ASC}`
         }
      }
   };

  return axios.get(`${window.apiBase}/public/tournaments`, config)
      .then(response => {
         return response.data;
      });
};

export function getSchoolUnionUpcomingTournaments(activeSchoolId: string, filter?: any) {
   const now = new Date();

   const where = propz.get(filter, ['where'], {});
   const skip = propz.get(filter, ['skip'], DEFAULT_SKIP);
   const limit = propz.get(filter, ['limit'], DEFAULT_LIMIT);
   const order = propz.get(filter, ['order'], `startTime ${SORT.ASC}`);

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $gte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               },
               ...where
            },
            skip,
            limit,
            order
         }
      }
   };

  return axios.get(`${window.apiBase}/public/tournaments`, config)
      .then(response => {
         return response.data;
      });
};

export function getSchoolUnionUpcomingTournamentsCount(activeSchoolId: string, where: any = {}) {
   const now = new Date();

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $gte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               },
               ...where
            }
         }
      }
   };

   return axios.get(`${window.apiBase}/public/tournaments/count`, config)
      .then(response => {
         return response.data;
      });
};

export function getAllSchoolUnionPastTournaments(activeSchoolId: string) {
   const now = new Date();

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $lte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               }
            },
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
            order: `startTime ${SORT.ASC}`
         }
      }
   };

  return axios.get(`${window.apiBase}/public/tournaments`, config)
      .then(response => {
         return response.data;
      });
};

export function getSchoolUnionPastTournaments(activeSchoolId: string, filter?: any) {
   const now = new Date();

   const where = propz.get(filter, ['where'], {});
   const skip = propz.get(filter, ['skip'], DEFAULT_SKIP);
   const limit = propz.get(filter, ['limit'], DEFAULT_LIMIT);
   const order = propz.get(filter, ['order'], `startTime ${SORT.DESC}`);

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $lte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               },
               ...where
            },
            skip,
            limit,
            order
         }
      }
   };

  return axios.get(`${window.apiBase}/public/tournaments`, config)
      .then(response => {
         return response.data;
      });
};

export function getSchoolUnionPastTournamentsCount(activeSchoolId: string, where: any = {}) {
   const now = new Date();

   const config = {
      params: {
         filter: {
            where: {
               isPublishOnTheWebsite: true,
               startTime: {
                  $lte: now
               },
               schoolId: {
                  $in: [activeSchoolId]
               },
               ...where
            }
         }
      }
   };

   return axios.get(`${window.apiBase}/public/tournaments/count`, config)
      .then(response => {
         return response.data;
      });
};